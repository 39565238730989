<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row>
        <b-col class="text-right">
          <b-button
            to="/package/create"
            size="sm"
            variant="success"
            class="mb-1"
          >
            <feather-icon icon="PlusSquareIcon" />
            {{$t('Create')}}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="match-height" v-if="!isLoading">
        <template v-if="packages && Object.keys(packages.data).length > 0">
        <template v-for="(data, index) in packages.data">
          <transition
          :name="$store.state.appConfig.layout.routerTransition"
          mode="out-in" :key="index"
          >
          <b-col md="12">
            <b-card no-body>
              <b-row>
                <b-col md="9" cols="8">
                  <b-card-body>
                    <b-row class="m-0">
                      #{{data.id}} - {{data.name}}
                    </b-row>
                    <b-card-text>
                    <div>
                        <div class="d-flex justify-content-between align-items-center">
                            <div>
                                <h6 class="text-muted font-weight-bolder">
                                {{$t("Quantity")}}
                                </h6>
                                <h3 class="mb-0">
                                {{data.quantity}}
                                </h3>
                            </div>
                            <div>
                                <h6 class="text-muted font-weight-bolder">
                                {{$t("Price")}} (SAR)
                                </h6>
                                <h3 class="mb-0">
                                {{data.price}}
                                </h3>
                            </div>
                            <div>
                                <h6 class="text-muted font-weight-bolder">
                                {{$t("Cost Price")}} (SAR)
                                </h6>
                                <h3 class="mb-0">
                                {{data.cost_price}}
                                </h3>
                            </div>
                            <div>
                                <h6 class="text-muted font-weight-bolder">
                                {{$t("Availability Date")}}
                                </h6>
                                <h3 class="mb-0">
                                {{data.availability_date | formatDateWithoutHours}}
                                </h3>
                            </div>
                        </div>
                    </div>
                    </b-card-text>
                  </b-card-body>
                </b-col>
                <b-col md="3" cols="4" align-self="center" class="text-center">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    :to="`/packages/${data.id}`"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="btn-icon"
                    v-on:click="deletePackage(data)"
                  >
                    <feather-icon icon="XCircleIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          </transition>
        </template>
        </template>
        <template v-else>
          <b-col>
            <b-card class="text-center">
                <span>{{$t('content not found')}}.</span>
            </b-card>
          </b-col>
        </template>
      </b-row>
      <pagination v-if="packages" :limit="7" :data="packages" @pagination-change-page="getPackages"></pagination>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'

export default {
  name: 'Packages',
  data() {
    return {
      packages: null,
      isLoading: false,
    }
  },
  mounted() {
    this.getPackages();
  },
  methods: {
    getPackages(page = 1){
      this.isLoading = true
      useJwt.get('/packages/get-all',{params: {
          page: page,
      }})
      .then((response) => {
        // console.log(response.data)
        this.packages = response.data.data
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    },
    deletePackage(item) {
        if(confirm("Do you really want to delete #"+item.id+"?")){
            this.isLoading = true
            useJwt.post('/packages/destroy',item)
            .then((response) => {
              this.packages = this.getPackages()
              this.isLoading = false
            })
            .catch(response => {
              // console.log(response);
            });
        }
    }
  }
}
</script>
